<template>
  <div class="login-box-right">
    <div class="title-box">
      <i class="iconfont">&#xe660;</i>
      公会后台管理系统
    </div>
    <div class="title-container">账号密码注册</div>
    <a-form :form="form" class="login-form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          v-decorator="[
            'account',
            { rules: [{ required: true, message: '请输入用户名!' }] },
          ]"
          placeholder="用户名"
          :allowClear="true"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '请输入密码!' }] },
          ]"
          type="password"
          placeholder="密码"
          :allowClear="true"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'password1',
            { rules: [{ required: true, message: '请输入确认密码!' }] },
          ]"
          type="password"
          placeholder="确认密码"
          :allowClear="true"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" class="login-form-button">
          点击注册
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  name: "LoginReg",
  components: {},
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true;
          const account = this.form.getFieldValue("account");
          const password = this.form.getFieldValue("password");
          const password1 = this.form.getFieldValue("password1");
          if (password != password1) {
            this.$message.error("密码与确认密码不符");
            return false;
          }

          let params = {
            account,
            password,
          };
          console.log(params);
          this.$api
            .post(`v1/user/register`, params)
            .then((resp) => {
              if (resp.code == 200) {
                // window.localStorage.setItem("token", resp.data.token);
                this.$router.push("/");
              } else {
                this.$message.error(resp.message);
              }
              this.logging = false;
            })
            .catch((e) => {
              this.logging = false;
              throw e;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  position: relative;
  height: 100vh;
  background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  overflow: hidden;

  .login-box {
    position: absolute;
    width: 1200px;
    height: 680px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 2px 1px 3px 0px rgba(255, 255, 255, 0.7),
      -2px -2px 6px 0px rgba(24, 83, 155, 0.78),
      -1px -1px 3px 0px rgba(10, 77, 151, 0.78),
      18px 9px 250px 0px rgba(255, 255, 255, 0.44);
    border-radius: 15px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/login-box.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    .login-box-left {
      width: 50%;
      height: 680px;
      background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/login-left.png);
      background-repeat: no-repeat;
      background-size: 100% 675px;
      background-position: center;
      margin-left: 3px;
      margin-top: 1px;
    }
    .login-box-right {
      width: 50%;
      height: 680px;
      position: relative;

      .title-box {
        font-size: 20px;
        font-weight: bold;
        color: #199aec;
        text-align: center;
        margin-top: 15px;
        .iconfont {
          line-height: 26px;
        }
      }
      .title-container {
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 50px;
        text-align: center;
      }
      .qrcode-box {
        width: 350px;
        height: 350px;
        margin: 25px auto;
        background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/qrode-box.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .tips-box {
        width: 370px;
        height: 47px;
        margin: 0 auto;
        background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/tips.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
      }
      .mei-left {
        width: 200px;
        height: 220px;
        position: absolute;
        background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/mei-left.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        bottom: -40px;
        left: -70px;
      }
      .mei-right {
        width: 200px;
        height: 207px;
        position: absolute;
        background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/mei-right.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        bottom: -40px;
        right: -70px;
      }

      .login-form {
        margin: 66px auto;
        max-width: 300px;
        .login-form-button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and(max-width: 750px) {
  .login-container {
    .login-box {
      .login-box-right {
        .title-container {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
